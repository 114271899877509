// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-page-tsx": () => import("../src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-course-page-tsx": () => import("../src/templates/course-page.tsx" /* webpackChunkName: "component---src-templates-course-page-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-thanks-tsx": () => import("../src/pages/contact/thanks.tsx" /* webpackChunkName: "component---src-pages-contact-thanks-tsx" */)
}

